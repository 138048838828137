import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import Section from "../components/common/Section";
import SectionHeading from "../components/common/SectionHeading";
import Layout from "../layouts";

const About = () => {
  const { t } = useI18next();

  return (
    <Layout title="general.about">
      <Section className="pt-20">
        <div className="flex flex-col gap-4 lg:flex-row lg:gap-16 mt-10">
          <div className="w-full lg:w-1/2">
            <SectionHeading as="h2">ConnectedLife</SectionHeading>
            <p className="my-4 text-left lg:text-justify text-text-primary dark:text-text-primary-dark">
              {t("index.hero1")}
            </p>
            <p className="my-4 text-left lg:text-justify text-text-primary dark:text-text-primary-dark">
              {t("index.hero2")}
            </p>
            <p className="my-4 text-left lg:text-justify text-text-primary dark:text-text-primary-dark">
              {t("index.hero3")}
            </p>
          </div>
          <div className="w-full lg:w-1/2">
            <StaticImage
              src="../static/images/about.jpg"
              objectFit="cover"
              className="rounded-lg"
            />
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default About;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
