import React from "react";

const SectionHeading = ({
  as: Component = "h2",
  caption,
  children,
  center,
}) => {
  return (
    <div
      className={`flex flex-col ${center ? "text-center items-center" : ""}`}
    >
      {caption && (
        <small className="text-primary uppercase text-sm md:text-base font-bold">
          {caption}
        </small>
      )}
      <Component
        className={`text-text-primary dark:text-text-primary-dark text-3xl md:text-5xl font-bold relative z-10 ${
          !caption
            ? "w-max before:block before:h-1 before:absolute before:w-full before:bg-primary before:bottom-0 before:left-0 before:-z-10"
            : ""
        }`}
      >
        {children}
      </Component>
    </div>
  );
};

export default SectionHeading;
